<template>
  <div>
    <div>
      <router-link
        :to="{ name: $props.show.name, params: { id: $props.show.params.id } }"
        class="nav-link-button"
        exact
        title="Show"
        ><v-icon small> mdi-eye </v-icon>
      </router-link>
      |
      <router-link
        :to="{ name: $props.edit.name, params: { id: $props.edit.params.id } }"
        class="nav-link-button"
        exact
        title="Edit"
        ><v-icon small> mdi-pencil </v-icon>
      </router-link>
      |
      <span>
        <v-icon @click="deleteObject" small title="Delete">
          mdi-delete
        </v-icon>
      </span>
      <slot></slot>
      <v-dialog v-model="showModal" max-width="500px">
        <v-card>
          <v-card-title class="text-h5"
            >Are you sure you want to delete this item?</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="cancel">Cancel</v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="deleteResource"
              :loading="loading"
              >OK</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>
<script>
import util from "@/util/util";
export default {
  props: {
    show: {
      type: Object,
    },
    edit: {
      type: Object,
    },
    delete: {
      type: Object,
    },
  },
  data() {
    return { loading: false, showModal: false };
  },
  mounted() {
    console.log("asd");
  },
  methods: {
    deleteObject() {
      this.showModal = true;
    },
    cancel() {
      this.showModal = false;
    },
    deleteResource() {
      this.loading = true;
      this.$http({
        url:
          this.$props.delete.params.name + "/" + this.$props.delete.params.id,
        method: "delete",
      }).then((res) => {
        this.loading = false;
        if (res.data.status == 0) {
          util.notify(0, res.data.errors[0]);
        } else {
          util.notify(1, this.$props.delete.params.name + " deleted");
          util.event.$emit("reload-resource", true);
          this.showModal = false;
        }
      });
    },
  },
};
</script>
<style scoped>
.fa-trash {
  color: #cc0000;
  cursor: pointer;
}

.fa-edit {
  color: #ffbf00;
}
.confirm-box {
  display: flex;
  justify-content: space-evenly;
}
.nav-link-button {
  display: inline !important;
  padding: 2px !important;
}
a {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87) !important ;
}
</style>
