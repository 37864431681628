<template
  ><div>
    <div>
      <v-toolbar flat>
        <v-toolbar-title>Contacts List</v-toolbar-title
        ><v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-menu>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" color="primary" dark class="mb-2">
              Add Contact
              <v-icon right dark> mdi-menu-down </v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item>
              <v-list-item-title class="a-parent">
                <router-link
                  :to="{ name: 'AddContact' }"
                  class="nav-link"
                  exact
                >
                  Add Contact
                </router-link>
              </v-list-item-title>
            </v-list-item>

            <v-list-item class="a-parent">
              <v-list-item-title v-on:click="importGoogleContact"
                >Google Contact</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar>
    </div>
    <div>
    
      <vue-table
        :key="key"
        :http="table.http"
        :url="table.url"
        :except="table.except"
        :html="table.html"
        :headingTransformer="table.headingTransformer"
        :additionalColumns="table.additionalColumns"
        :valueTransformer="table.valueTransformer"
        :additionalColumnsTransformer="table.additionalColumnsTransformer"
        :columnSortOrder="table.columnSortOrder"
      ></vue-table>
    </div>
  </div>
</template>
<script>
import util from "@/util/util";
import action from "@/components/Contact/action.vue";
import vueTable from "@/components/shared/vue-table.vue";
export default {
  components: { vueTable },
  data() {
    return {
      util,
      key: 0,
      table: {
        http: util.http,
        url: "customer",
        additionalColumns: ["name", "Actions"],
        html: [
          "name",
          "company",
          "customer_type",
          "address",
          "status",
          "Actions",
        ],
        columnSortOrder: {
          name: 1,
          customer_type: 2,
          company: 3,
        },
        headingTransformer: function(val) {
          return util.camelCase(val);
        },
        except: [
          "id",
          "created_at",
          "updated_at",
          "deleted_at",
          "company_id",
          "customer_type_id",
          "first_name",
          "middle_name",
          "last_name",
          "city",
          "state",
          "country",
        ],
        valueTransformer: function() {
          return {
            company: (row) => {
              return [
                {
                  item: row.company.company_name,
                  handler: () => null,
                },
              ];
            },
            customer_type: (row) => {
              return [
                {
                  item: row.customer_type.name,
                  handler: () => null,
                },
              ];
            },
            address: (row) => {
              return [
                {
                  item:
                    row.address +
                    ", " +
                    row.city +
                    ", " +
                    row.state +
                    ", " +
                    row.country,
                  handler: () => null,
                },
              ];
            },
            status: (row) => {
              return [
                {
                  item:
                    '<span style="color:white;padding:0.4rem" class="badge ' +
                    (row.status == 0 ? "new badge red" : "new badge green") +
                    '">' +
                    (row.status == 0 ? "Not Active" : "Active") +
                    "</span>",
                  handler: () => null,
                },
              ];
            },
          };
        },
        additionalColumnsTransformer: function() {
          return {
            name: (row) => {
              return [
                {
                  item:
                    row.first_name +
                    " " +
                    (row.middle_name != null ? row.middle_name + " " : "") +
                    row.last_name,
                  handler: () => null,
                },
              ];
            },
            Actions: (row) => {
              return [
                {
                  comp: action,
                  prop: {
                    item: row,
                    show: {
                      name: "single-contact",
                      params: {
                        id: row.id,
                        permission: "show-customer",
                      },
                    },
                    edit: {
                      name: "EditContact",
                      params: {
                        id: row.id,
                        // permission: "edit-customer",
                      },
                    },
                    delete: {
                      params: { name: "customer", id: row.id },
                      // permission: "delete-customer",
                    },
                  },
                },
              ];
            },
          };
        },
      },
    };
  },
  methods: {
    importGoogleContact() {
      util
        .http({
          url: "import-contact/google",
          method: "get",
        })
        .then((res) => {
          if (res.data.status == 0) {
            util.notify(0, res.data.errors[0]);
          } else {
            window.location.href = res.data.data;
            // window.open(res.data.data, "_blank");
          }
        });
    },
  },
  mounted() {
    util.event.$on("reload-resource", () => {
      this.key++;
    });
  },
};
</script>
<style scoped>
a {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87) !important ;
}
.a-parent {
  cursor: pointer;
}
.search-button {
  margin-bottom: 1.2rem;
  text-align: center;
  background: #40826d !important;
}
.search-button i {
  margin-right: 0 !important;
}
</style>
